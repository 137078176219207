@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  font-family: 'DM Sans', sans-serif !important;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.react-date-picker__wrapper {
  border: 1px solid #D6D6DA !important;
  border-radius: 4px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.react-date-picker__wrapper input::placeholder {
  color: #AAB0BC;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}